import { j as l, T as dt, c as N, s as ft, C as mt, p as H, L as st, H as pt, I as bt, a as ht, D as xt, b as gt, d as _t } from "./TextLink-BIGKPSR8.mjs";
import { A as Oe, B as Be, e as Fe, J as Pe, f as De, R as Re, g as Se } from "./TextLink-BIGKPSR8.mjs";
import { forwardRef as q, useMemo as E, useState as B, useRef as F, useCallback as T, useEffect as M, useId as X, useLayoutEffect as at } from "react";
import { createPortal as Tt } from "react-dom";
const wt = (e, t) => Number(e === "en" ? String(t).replace(/[^0-9.]/g, "") : String(t).replace(/[^0-9,]/g, "").replace(/,/g, ".")), et = {}, yt = (e, t, n, o) => {
  const r = `${e}-${t ? "currency" : "noCurrency"}-${n}-${o}`;
  let i = et[r];
  return i || (i = new Intl.NumberFormat(e ?? "de", {
    minimumFractionDigits: n,
    maximumFractionDigits: n,
    ...t ? { style: "currency", currency: "EUR" } : {},
    ...o ? {} : { useGrouping: !1 }
  }), et[r] = i), i;
};
function V(e, t, n = {}) {
  const o = (n == null ? void 0 : n.precision) === void 0 ? 2 : n == null ? void 0 : n.precision, s = typeof t == "number" ? t || 0 : wt(e, t), r = (n == null ? void 0 : n.thousandSeparator) ?? !0;
  return yt(e, !(n != null && n.format), o, r).format(s).replace(/\u00A0/, " ");
}
function nt(e) {
  if (!e)
    return 0;
  const t = e.replace(/,/g, "."), n = parseFloat(t) || 0;
  return Math.round(n * 100);
}
function U(e, t = 2) {
  return e.replace(/[^\d.,]/g, "").replace(/^([.,]{1,1})/, "0$1").replace(/^0{2,}/, "0").replace(/^0([\d]+)([\d,.]*)/, "$1$2").replace(/[.,]([^.,]*)(?=[.,])/g, "$1").replace(new RegExp(`([.,]\\d{${t}}).*$`), "$1");
}
const we = [
  "text",
  "number",
  "email",
  "password",
  "search",
  "tel",
  "date",
  "datetime-local",
  "time",
  "url",
  "color"
];
function ot(e) {
  if (typeof e > "u") return;
  let t;
  if (typeof e != "number" ? t = parseFloat(e) : t = e, !isNaN(t))
    return Math.round(t * 100);
}
const Lt = q(
  ({
    label: e,
    locale: t = "de",
    name: n,
    id: o,
    min: s = 0,
    onChange: r,
    onBlur: i,
    onFocus: c,
    defaultValue: u,
    value: f,
    step: p,
    children: h,
    className: L,
    hideSteps: k,
    max: y,
    ...w
  }, C) => {
    const x = E(() => Ct(p), [p]), [_, I] = B(
      U(
        V(t, f ?? u ?? "", { precision: x, format: "%v", thousandSeparator: !1 }),
        x
      )
    ), O = E(() => ot(y), [y]), a = E(() => ot(s), [s]), [m, g] = B(null), $ = F(null), W = T(
      (b) => {
        if ($.current = b, !!C) {
          if (typeof C == "function") return C(b);
          C.current = b;
        }
      },
      [C]
    ), d = T(
      (b) => {
        const v = rt(b.target.value, t, x), J = nt(v);
        if (typeof O < "u" && J > O || typeof a < "u" && J < a) {
          const ut = b.target.selectionStart ? b.target.selectionStart : 0;
          return g(ut), b.preventDefault();
        }
        g(b.target.selectionStart);
        let Z = b.target.value;
        v.startsWith(b.target.value) || (Z = v);
        const tt = nt(b.target.value);
        b.target.value && !isNaN(tt) && J === tt && (r == null || r(b)), I(Z);
      },
      [t, x, a, O, r]
    ), A = T(
      (b) => {
        const v = rt(b.target.value, t, x);
        v !== b.target.value && (b.target.value = v), g(null), i == null || i(b);
      },
      [i, x, t]
    ), j = T(
      (b) => {
        g(b.target.selectionStart), c == null || c(b);
      },
      [c]
    ), R = E(
      () => u ? { defaultValue: _ } : { value: _ },
      [u, _]
    );
    return M(() => {
      if (typeof u < "u") return;
      const b = f ?? u;
      if (typeof f > "u")
        return I("");
      const v = U(
        V(t, b, { precision: x, format: "%v", thousandSeparator: !1 }),
        x
      );
      I(v);
    }, [f, x, u, t]), M(() => {
      const b = (_ == null ? void 0 : _.length) ?? 0, v = (m ?? 0) > b ? b : m;
      m === null || !$.current || document.activeElement !== $.current || $.current.setSelectionRange(v, v);
    }, [m, _]), /* @__PURE__ */ l.jsx(
      dt,
      {
        ...w,
        ...R,
        onFocus: j,
        onBlur: A,
        onChange: d,
        step: p,
        ref: W,
        id: o || n,
        name: n,
        label: e,
        pattern: "[0-9]*[.,]?[0-9]*",
        type: "text",
        inputMode: "decimal",
        className: N(L, { [ft.hideSteps]: k }),
        children: h
      }
    );
  }
);
Lt.displayName = "ClientAmountField";
function rt(e, t, n = 2) {
  if (typeof e > "u" || e === null)
    return e;
  const o = String(e), s = U(o, n), r = V(t, s, { precision: n, format: "%v", thousandSeparator: !1 }), i = U(r, n);
  if (!n)
    return i;
  const c = i.search(/[.,]/);
  if (c < 0)
    return i;
  const u = n - (i.length - 1 - c);
  return i.concat("0".repeat(u));
}
function Ct(e) {
  var n;
  return e === void 0 ? void 0 : ((n = String(e).split(".")[1]) == null ? void 0 : n.length) ?? 0;
}
const ye = q(function({ onChange: t, onClick: n, ...o }, s) {
  const { disabled: r } = o, i = r ? H : t, c = r ? H : n;
  return /* @__PURE__ */ l.jsx(mt, { ...o, onChange: i, onClick: c, ref: s });
}), kt = "_fieldset_hid4k_1", At = "_fieldContainer_hid4k_30", Nt = "_groupLabel_hid4k_34", It = "_labelLarge_hid4k_39", jt = "_groupHint_hid4k_43", vt = "_groupError_hid4k_48", $t = "_errorMessage_hid4k_57", Mt = "_disabledLabels_hid4k_64", z = {
  fieldset: kt,
  fieldContainer: At,
  groupLabel: Nt,
  labelLarge: It,
  groupHint: jt,
  groupError: vt,
  errorMessage: $t,
  disabledLabels: Mt
}, Ot = "_modal_36g8m_1", Bt = "_modalInner_36g8m_51", Ft = "_modalHeader_36g8m_55", Pt = "_closeButton_36g8m_70", Dt = "_closeIcon_36g8m_76", Rt = "_modalContent_36g8m_80", St = "_modalActions_36g8m_84", S = {
  modal: Ot,
  modalInner: Bt,
  modalHeader: Ft,
  closeButton: Pt,
  closeIcon: Dt,
  modalContent: Rt,
  modalActions: St
};
function Et(e) {
  e && (!("close" in e) || typeof e.close != "function" || e.close());
}
function Le({
  id: e,
  isOpen: t,
  onClose: n,
  onOpen: o,
  onCloseComplete: s,
  onOpenComplete: r,
  title: i,
  customActions: c,
  children: u,
  manualFocusRef: f,
  closeText: p,
  closeOnClickOutside: h = !0,
  hideHeader: L = !1,
  hideActions: k = !1,
  classNames: y = {}
}) {
  const w = F(null), C = F(null), [x, _] = B(!0), I = T(() => f || (C.current ? C : w), [f]), O = X(), a = e || O, m = T((d) => {
    var b;
    if (d.key !== "Tab") return;
    const A = (b = w.current) == null ? void 0 : b.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    ), j = A == null ? void 0 : A[0], R = A == null ? void 0 : A[A.length - 1];
    if (d.shiftKey && document.activeElement === j) {
      d.preventDefault(), R == null || R.focus();
      return;
    }
    !d.shiftKey && document.activeElement === R && (d.preventDefault(), j == null || j.focus());
  }, []), g = T(() => {
    s == null || s(), _(!0);
  }, [s]), $ = T(() => {
    var d;
    r == null || r(), _(!0), (d = w.current) == null || d.removeAttribute("inert");
  }, [r]);
  M(() => {
    var A;
    _(!1);
    const d = w.current;
    if (d) {
      if (t) {
        d.showModal();
        const j = I();
        (A = j == null ? void 0 : j.current) == null || A.focus(), d.removeEventListener("transitionend", g), d.addEventListener("keydown", m), d.addEventListener("transitionend", $), o == null || o();
      } else
        d.removeEventListener("keydown", m), d.removeEventListener("transitionend", $), d.addEventListener("transitionend", g), Et(d), d.setAttribute("inert", "");
      return () => {
        d == null || d.removeEventListener("keydown", m), d.removeEventListener("transitionend", g), d.removeEventListener("transitionend", $);
      };
    }
  }, [I, m, t, g, o, $]);
  const W = T(
    (d) => {
      const { current: A } = w;
      d.target === A && n();
    },
    [n]
  );
  return (
    // Disabled because HTML dialog brings keyboard interaction with it
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    /* @__PURE__ */ l.jsx(
      "dialog",
      {
        id: a,
        ref: w,
        className: N(S.modal, y.modal),
        onCancel: n,
        onClick: h ? W : void 0,
        "aria-labelledby": `modal-title-${a}`,
        "aria-modal": "true",
        title: i,
        tabIndex: -1,
        children: /* @__PURE__ */ l.jsxs(st, { space: "400", className: N(S.modalInner, y.modalInner), children: [
          !L && /* @__PURE__ */ l.jsxs("div", { className: N(S.modalHeader, y.titleContainer), children: [
            /* @__PURE__ */ l.jsx(pt, { level: "h200", as: "h2", id: `modal-title-${a}`, children: i }),
            /* @__PURE__ */ l.jsx(
              bt,
              {
                kind: "plain",
                className: S.closeButton,
                onClick: n,
                ref: C,
                type: "button",
                iconName: "x",
                iconProps: { title: p, color: "fg-content-primary" }
              }
            )
          ] }),
          /* @__PURE__ */ l.jsx("div", { className: N(S.modalContent, y.contentContainer), children: (t || !x) && u }),
          !k && /* @__PURE__ */ l.jsx("div", { className: N(S.modalActions, y.actionsContainer), children: c || /* @__PURE__ */ l.jsx(ht, { onClick: n, type: "button", children: p }) })
        ] })
      }
    )
  );
}
const zt = "_cluster_1iwsd_1", Ht = "_clustered_1iwsd_7", Wt = "_justified_1iwsd_11", Y = {
  cluster: zt,
  clustered: Ht,
  justified: Wt
};
function qt({
  as: e = "div",
  alignment: t = "clustered",
  className: n,
  style: o,
  space: s = "200",
  ...r
}) {
  return /* @__PURE__ */ l.jsx(
    e,
    {
      ...r,
      className: N(Y.cluster, n, {
        [Y.justified]: t === "justified",
        [Y.clustered]: t === "clustered"
      }),
      style: {
        ...o,
        "--space": `var(--betterplace-spacing-${s})`
      },
      children: r.children
    }
  );
}
function Kt({ className: e, hint: t, required: n, children: o }) {
  return /* @__PURE__ */ l.jsxs("legend", { className: e, children: [
    /* @__PURE__ */ l.jsxs("div", { className: z.groupLabel, children: [
      o,
      n && /* @__PURE__ */ l.jsx("span", { "aria-label": "(required)", children: "*" })
    ] }),
    t ? /* @__PURE__ */ l.jsx("div", { className: z.groupHint, children: t }) : null
  ] });
}
function Ce({
  children: e,
  classNames: t,
  legend: n,
  hint: o,
  required: s,
  error: r,
  layout: i = "horizontal",
  layoutProps: c = {
    space: "none"
  },
  errorDisplay: u = "all",
  ...f
}) {
  const p = N(z.fieldContainer, c == null ? void 0 : c.className, {
    [z.groupError]: !!r && u !== "text"
  }), h = i === "vertical" ? st : qt, L = X(), k = f.id || L, y = r ? `${k}-error` : void 0;
  return /* @__PURE__ */ l.jsxs(
    "fieldset",
    {
      id: k,
      className: N(z.fieldset, t == null ? void 0 : t.fieldset),
      "aria-describedby": y,
      ...f,
      children: [
        n || o ? /* @__PURE__ */ l.jsx(Kt, { required: s, hint: o, className: t == null ? void 0 : t.legend, children: n }) : null,
        /* @__PURE__ */ l.jsx(h, { className: p, ...c, children: e }),
        r && u !== "border" ? /* @__PURE__ */ l.jsx("p", { id: y, className: z.errorMessage, "aria-live": "polite", role: "alert", children: r }) : null
      ]
    }
  );
}
const ke = q(function({ onChange: t, onClick: n, ...o }, s) {
  const { disabled: r } = o, i = r ? H : t, c = r ? H : n;
  return /* @__PURE__ */ l.jsx(xt, { ...o, onChange: i, onClick: c, ref: s });
}), Ut = "_input_xcovg_1", Xt = "_growWrap_xcovg_2", K = {
  input: Ut,
  growWrap: Xt,
  "input--error": "_input--error_xcovg_24"
}, Ae = q(function({ label: t, name: n, description: o, error: s, warning: r, id: i, required: c, className: u, onChange: f, errorDisplay: p, rows: h = 1, ...L }, k) {
  const y = F(null), w = [];
  return s && w.push(`${n}-error`), r && w.push(`${n}-warning`), o && w.push(`${n}-description`), /* @__PURE__ */ l.jsx(
    gt,
    {
      label: t,
      name: n,
      description: o,
      error: s,
      warning: r,
      id: i || n,
      required: c,
      errorDisplay: p,
      /**
       * The following trick is used for the auto resizing textarea: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
       * We need to make sure the same styles are applied to both the grow wrapper and the input
       */
      children: /* @__PURE__ */ l.jsx("div", { className: N(K.growWrap, u, { [K.wrapError]: !!s }), ref: y, children: /* @__PURE__ */ l.jsx(
        "textarea",
        {
          ...L,
          ref: k,
          id: i || n,
          name: n,
          "aria-describedby": w.length ? w.join(" ") : void 0,
          "aria-invalid": !!s,
          "aria-required": c,
          className: N(K.input, u, { [K["input--error"]]: !!s }),
          rows: h,
          onChange: (C) => {
            var x;
            (x = y.current) == null || x.setAttribute("data-replicated-value", C.target.value), f && f(C);
          }
        }
      ) })
    }
  );
}), Jt = "_button_akv9v_1", Yt = "_hiddenInput_akv9v_62", G = {
  button: Jt,
  hiddenInput: Yt,
  "size-large": "_size-large_akv9v_68",
  "size-default": "_size-default_akv9v_73",
  "size-small": "_size-small_akv9v_78"
}, Ne = q(function({
  id: t,
  children: n,
  value: o,
  name: s,
  onChange: r,
  onClick: i,
  className: c,
  type: u = "radio",
  size: f = "default",
  disabled_: p,
  disabled: h,
  ...L
}, k) {
  const y = N(G.button, G[`size-${f}`], c), w = X(), x = `${t || s || w}-${String(o)}`, _ = h || p;
  return /* @__PURE__ */ l.jsxs("label", { htmlFor: x, className: y, children: [
    /* @__PURE__ */ l.jsx(
      "input",
      {
        ...L,
        ref: k,
        id: x,
        "aria-disabled": _,
        type: u,
        value: o,
        name: s,
        onClick: _ ? H : i,
        onChange: _ ? H : r,
        className: G.hiddenInput,
        disabled: p
      }
    ),
    n
  ] });
}), Gt = "_tooltipContent_15m5t_1", Qt = "_tooltipContainer_15m5t_11", Vt = "_tooltipArrow_15m5t_19", Zt = "_bottomTooltipArrow_15m5t_50", te = "_topTooltipArrow_15m5t_54", ee = "_spyElement_15m5t_58", ne = "_triggerContainer_15m5t_64", oe = "_nonFocusableOutline_15m5t_74", P = {
  tooltipContent: Gt,
  tooltipContainer: Qt,
  tooltipArrow: Vt,
  bottomTooltipArrow: Zt,
  topTooltipArrow: te,
  spyElement: ee,
  triggerContainer: ne,
  nonFocusableOutline: oe
}, D = {
  TOP: "top",
  BOTTOM: "bottom"
}, re = {
  a: "link",
  area: "link",
  article: "article",
  aside: "complementary",
  button: "button",
  details: "group",
  dfn: "term",
  dialog: "dialog",
  fieldset: "group",
  figure: "figure",
  footer: "contentinfo",
  form: "form",
  h1: "heading",
  h2: "heading",
  h3: "heading",
  h4: "heading",
  h5: "heading",
  h6: "heading",
  header: "banner",
  hr: "separator",
  iframe: "document",
  img: "img",
  input: null,
  // for type purposes this value needs to be included
  li: "listitem",
  main: "main",
  menu: "list",
  menuitem: "menuitem",
  meter: "meter",
  nav: "navigation",
  ol: "list",
  option: "option",
  progress: "progressbar",
  section: "region",
  select: "listbox",
  summary: "button",
  table: "table",
  tbody: "rowgroup",
  td: "cell",
  textarea: "textbox",
  tfoot: "rowgroup",
  th: "columnheader",
  thead: "rowgroup",
  tr: "row",
  ul: "list"
}, ie = {
  button: "button",
  checkbox: "checkbox",
  radio: "radio",
  range: "slider",
  text: "textbox",
  password: "textbox",
  email: "textbox",
  search: "searchbox",
  number: "spinbutton",
  tel: "textbox",
  url: "textbox",
  submit: "button",
  reset: "button",
  image: "button",
  file: "textbox"
}, se = ["INPUT", "SELECT", "TEXTAREA", "A", "BUTTON", "AREA"];
function ae(e) {
  return !e || !e.trim() ? /* @__PURE__ */ new Set() : new Set(e.trim().split(/\s+/));
}
function ce(e, t) {
  return [...ae(e).add(t)].join(" ");
}
function le(e) {
  const t = e.getAttribute("role");
  if (t) return t;
  const n = e.tagName.toLowerCase();
  if (n === "input") {
    const s = e.getAttribute("type");
    return s ? ie[s] : "textbox";
  }
  const o = re[n];
  return o || "button";
}
function ct(e) {
  if (!e || !(e instanceof HTMLElement)) return null;
  if (se.includes(e.tagName))
    return e;
  for (const t of e.children) {
    const n = ct(t);
    if (n)
      return n;
  }
  return null;
}
function ue(e, t) {
  return JSON.stringify(e) === JSON.stringify(t);
}
function lt(e) {
  return (t) => t === e || t && ue(t, e) ? t : e;
}
const Q = {
  enter: "Enter",
  space: " ",
  tab: "Tab",
  escape: "Escape"
};
function it({ x: e, position: t }) {
  return /* @__PURE__ */ l.jsx(
    "div",
    {
      className: N(P.tooltipArrow, {
        [P.bottomTooltipArrow]: t === D.BOTTOM,
        [P.topTooltipArrow]: t === D.TOP
      }),
      style: { transform: `translateX(${e}px) ${t === D.BOTTOM ? " rotate(180deg)" : ""}` }
    }
  );
}
function de() {
  const e = document.getElementById("bp-portal-target");
  if (e) return e;
  const t = document.createElement("div");
  return t.id = "bp-portal-target", t.style.position = "absolute", t.setAttribute("role", "tooltip"), document.body.appendChild(t), t;
}
function fe({
  triggerRect: e,
  id: t,
  onTooltipLeave: n,
  children: o,
  triggerEvents: s
}) {
  const r = F(null), [i, c] = B(null), [u, f] = B(null), p = T(() => {
    var I;
    const _ = ((I = r == null ? void 0 : r.current) == null ? void 0 : I.getBoundingClientRect()) ?? null;
    c(lt(_ && { width: _.width, height: _.height }));
  }, []), h = T(() => {
    const _ = document.body.getBoundingClientRect();
    f(_.width);
  }, []);
  at(() => {
    p(), h();
  }, [p, h]);
  const L = T(() => {
    window.requestAnimationFrame(() => {
      p(), h();
    });
  }, [p, h]), k = T(
    (_) => {
      s != null && s.includes("hover") && n(_);
    },
    [n, s]
  );
  M(() => (window.addEventListener("resize", L), () => {
    window.removeEventListener("resize", L);
  }), [L]);
  const { x: y, y: w, arrowTooltipOffsetX: C, position: x } = E(
    () => pe(e, i, u),
    [e, i, u]
  );
  return Tt(
    /* @__PURE__ */ l.jsxs(
      "div",
      {
        id: t,
        className: P.tooltipContainer,
        ref: r,
        onPointerLeave: k,
        style: {
          transform: `translate3d(${y}px, ${w}px, 0)`
        },
        children: [
          x === D.BOTTOM && /* @__PURE__ */ l.jsx(it, { position: x, x: C }),
          /* @__PURE__ */ l.jsx("div", { className: P.tooltipContent, children: o }),
          x === D.TOP && /* @__PURE__ */ l.jsx(it, { position: x, x: C })
        ]
      }
    ),
    de()
  );
}
function me({ children: e, content: t, id: n, triggerEvents: o }) {
  const s = X(), r = n || s, i = F(null), [c, u] = B(null);
  M(() => {
    var j;
    const a = (j = i.current) == null ? void 0 : j.nextSibling;
    if (!a) return;
    const m = ct(a), g = m ?? a, W = g.getAttribute("aria-roledescription") ?? le(g) ?? "button";
    g.setAttribute("role", "button"), m || (g == null || g.setAttribute("tabindex", "0"), g == null || g.classList.add(P.nonFocusableOutline)), g.setAttribute("aria-roledescription", W);
    const d = g.getAttribute("aria-describedby");
    (d == null ? void 0 : d.includes(r)) || g.setAttribute("aria-describedby", ce(d, r));
  }, [e, r]);
  const f = T(() => {
    var g;
    const a = (g = i == null ? void 0 : i.current) == null ? void 0 : g.nextSibling;
    let m = (a == null ? void 0 : a.getBoundingClientRect()) ?? null;
    m != null && m.height || (m = null), u(lt(m && { left: m.left, top: m.top, bottom: m.bottom, width: m.width }));
  }, [i]), p = T(() => {
    c && f();
  }, [f, c]), h = T(() => {
    u(null);
  }, []), L = T(() => {
    c ? h() : f();
  }, [f, h, c]), k = T(
    (a) => {
      const m = document.getElementById(s);
      if (a.relatedTarget instanceof Node && (m != null && m.contains(a.relatedTarget)))
        return a.preventDefault();
      h();
    },
    [h, s]
  ), y = T(
    (a) => {
      var m, g;
      if (a.relatedTarget instanceof Node && ((g = (m = i == null ? void 0 : i.current) == null ? void 0 : m.nextSibling) != null && g.contains(a.relatedTarget)))
        return a.preventDefault();
      h();
    },
    [h]
  ), w = T(
    (a) => {
      o != null && o.includes("click") && (a.preventDefault(), L());
    },
    [L, o]
  ), C = T(
    (a) => {
      if (o != null && o.includes("keypress") && (a.key === Q.enter || a.key === Q.space)) {
        a.preventDefault(), L();
        return;
      }
      a.key === Q.escape && h();
    },
    [h, L, o]
  ), x = T(() => {
    o != null && o.includes("focus") && f();
  }, [f, o]), _ = T(() => {
    o != null && o.includes("focus") && h();
  }, [h, o]), I = T(() => {
    o != null && o.includes("hover") && f();
  }, [f, o]), O = T(
    (a) => {
      o != null && o.includes("hover") && k(a);
    },
    [k, o]
  );
  return M(() => {
    var m;
    const a = (m = i.current) == null ? void 0 : m.nextSibling;
    return a == null || a.addEventListener("click", w), a.addEventListener("pointerenter", I), a.addEventListener("pointerleave", O), a.addEventListener("focus", x), a.addEventListener("focusin", x), a.addEventListener("focusout", _), a.addEventListener("keydown", C), () => {
      a.removeEventListener("click", w), a.removeEventListener("pointerenter", I), a.removeEventListener("pointerleave", O), a.removeEventListener("focus", x), a.removeEventListener("focusin", x), a.removeEventListener("focusout", _), a.removeEventListener("keydown", C);
    };
  }, [w, x, C, I, O, L, i, _, e]), M(() => (window.addEventListener("resize", p), () => {
    window.removeEventListener("resize", p);
  }), [p]), at(() => {
    p();
  }, [e, p]), /* @__PURE__ */ l.jsxs(l.Fragment, { children: [
    /* @__PURE__ */ l.jsx("div", { ref: i, className: P.spyElement }),
    e,
    c && /* @__PURE__ */ l.jsx(
      fe,
      {
        triggerRect: c,
        id: r,
        onTooltipLeave: y,
        triggerEvents: o,
        children: t
      }
    )
  ] });
}
function Ie({ children: e, content: t, id: n, triggerEvents: o = ["hover", "focus"], inactive: s }) {
  const [r, i] = B(!1);
  return M(() => (i(!0), () => {
    i(!1);
  }), []), !r || s ? e : /* @__PURE__ */ l.jsx(me, { content: t, id: n, triggerEvents: o, children: e });
}
function pe(e, t, n, o = 20, s = 17) {
  let r = 0, i = 0, c = 0, u = 0, f = D.TOP;
  if (!e || !t || !n) return { x: r, y: i, arrowTooltipOffsetX: u, position: f };
  const p = t.width > n ? 0 : Math.min(o, (n - t.width) / 2), h = t.width / 2, L = e.width / 2;
  r = e.left - h + L;
  const k = r;
  c = r + t.width, r < p ? r = p : c > n - p && (r -= c - (n - p)), u = k - r + h - s;
  const y = u + 2 * s - t.width;
  if (y > 0) {
    const w = Math.min(y, p);
    r += w, u -= w;
  }
  return i = e.top - t.height, i < 0 && (f = D.BOTTOM, i = e.bottom), { x: r, y: i, arrowTooltipOffsetX: u, position: f };
}
const be = "_tooltipTrigger_36bik_1", he = {
  tooltipTrigger: be
}, je = ({ type: e = "question", title: t = "More information", className: n }) => /* @__PURE__ */ l.jsx("button", { className: N(he.tooltipTrigger, n), type: "button", children: /* @__PURE__ */ l.jsx(_t, { name: e, title: t }) });
function xe(e, t, n) {
  const o = F(null), s = F(null), [r, i] = B(null);
  return M(() => {
    s.current = t ?? null;
  }, [t]), M(() => (o.current = new MutationObserver((c) => {
    for (const u of c)
      (typeof s.current != "function" || s.current(u)) && i(u);
  }), o.current.observe(e.current, n), () => {
    var c;
    (c = o.current) == null || c.disconnect();
  }), []), r;
}
function ve(e, t) {
  const n = T(
    (r) => r.type === "attributes" && r.attributeName === t,
    [t]
  ), o = xe(e, n, { attributes: !0 });
  return E(
    () => {
      var r;
      return { oldValue: (o == null ? void 0 : o.oldValue) ?? null, newValue: (r = e.current) == null ? void 0 : r.getAttribute(t) };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [o, t]
  );
}
export {
  Oe as Alert,
  we as AllowedInputTypes,
  Lt as AmountField,
  Be as Badge,
  ht as Button,
  Fe as Card,
  ye as Checkbox,
  ke as Dropdown,
  Ce as Fieldset,
  pt as Heading,
  _t as Icon,
  bt as IconButton,
  Pe as JSONPrettyPrint,
  De as LayoutCenter,
  qt as LayoutCluster,
  Le as LayoutModal,
  st as LayoutStack,
  D as Position,
  Re as RadioButton,
  dt as TextInput,
  Se as TextLink,
  Ae as Textarea,
  Ne as ToggleButton,
  Ie as Tooltip,
  je as TooltipTrigger,
  ve as useAttributeObserver,
  xe as useMutationObserver
};
